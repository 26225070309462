var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.ifSub}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"负责人","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'chargeUser.id',
              {
                validateTrigger: ['change'],
                rules: [{
                  type: 'string',
                  required: true,
                  message: '请选择负责人',
                }]
              }
            ]),expression:"[\n              'chargeUser.id',\n              {\n                validateTrigger: ['change'],\n                rules: [{\n                  type: 'string',\n                  required: true,\n                  message: '请选择负责人',\n                }]\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"treeDefaultExpandAll":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.userTreeData,"placeholder":"请选择负责人"}})],1),_c('a-form-item',{attrs:{"label":"司机","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'driver.id',
              {
                validateTrigger: ['change'],
                rules: [{
                  type: 'string',
                  required: true,
                  message: '请选择司机',
                }]
              }
            ]),expression:"[\n              'driver.id',\n              {\n                validateTrigger: ['change'],\n                rules: [{\n                  type: 'string',\n                  required: true,\n                  message: '请选择司机',\n                }]\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"treeDefaultExpandAll":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.userTreeData,"placeholder":"请选择司机"}})],1),_c('a-form-item',{attrs:{"label":"车牌号码","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carInfo.id', { rules: [{ required: true, message: '请选择车辆' }] }]),expression:"['carInfo.id', { rules: [{ required: true, message: '请选择车辆' }] }]"}],attrs:{"placeholder":"请选择车辆"}},_vm._l((_vm.carList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.platNumber)+"("+_vm._s(item.status==='FREE'?'空闲中':item.status==='GOING'?'执行中':'离线')+") ")])}),1)],1),_c('a-form-item',{attrs:{"label":"是否默认","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ifDefault', {initialValue: 'NO', rules: [{ required: true, message: '请选择是否默认' }] }]),expression:"['ifDefault', {initialValue: 'NO', rules: [{ required: true, message: '请选择是否默认' }] }]"}]},[_c('a-radio',{attrs:{"value":"YES"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"NO"}},[_vm._v(" 否 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }