// 司机车辆配置列表表头
export const carConfigColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '车辆负责人',
      width: 100,
      align: 'center',
      dataIndex: 'chargeUser.name'
    },
    {
      title: '司机姓名',
      width: 100,
      align: 'center',
      dataIndex: 'driver.name'
    },
    {
      title: '车牌号码',
      width: 200,
      align: 'center',
      dataIndex: 'carInfo.platNumber'
    },
    {
      title: '是否默认',
      width: 80,
      align: 'center',
      dataIndex: 'ifDefault',
      scopedSlots: { customRender: 'ifDefault' }
    },
    {
      title: '操作',
      width: 120,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 车辆用途类型
export const useList = function () {
  return [
    { value: 'OTHER', label: '其他' },
    { value: 'QY', label: '清运' },
    { value: 'XJ', label: '巡检' }
  ]
}

