<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="负责人"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'chargeUser.id',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择负责人',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="userTreeData"
          placeholder="请选择负责人"
        />
      </a-form-item>
      <a-form-item label="司机"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'driver.id',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择司机',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="userTreeData"
          placeholder="请选择司机"
        />
      </a-form-item>
      <a-form-item label="车牌号码" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select v-decorator="['carInfo.id', { rules: [{ required: true, message: '请选择车辆' }] }]" placeholder="请选择车辆">
          <a-select-option
            v-for="item in carList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.platNumber }}({{item.status==='FREE'?'空闲中':item.status==='GOING'?'执行中':'离线'}})
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否默认" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-decorator="['ifDefault', {initialValue: 'NO', rules: [{ required: true, message: '请选择是否默认' }] }]">
          <a-radio value="YES">
            是
          </a-radio>
          <a-radio value="NO">
            否
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { carConfigFormVO } from './common/carConfigFormVO'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'CarConfigForm',
    mixins: [entityCURDCallback],
    props: {
      type: {
        type: String,
        default: 'add'
      },
    },
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carConfigApi,
        initFormItem: carConfigFormVO(),
        showFormItem: false,
        userTreeData: [],
        carList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    mounted() {
      this.loadCarInfoList()
      if (this.type === 'add') {
        this.LoadUserList()
      }
     // this.search()
    },
    methods: {
      loadCarInfoList() {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      LoadUserList() {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userTreeData = data.body
            if (this.type === 'edit') {
              var chargeUserKey = this.getSelectKey(this.userTreeData, this.formItem.chargeUser.id)
              var driverUserKey = this.getSelectKey(this.userTreeData, this.formItem.driver.id)
              if (chargeUserKey) {
                this.form.setFieldsValue({
                  'chargeUser.id': chargeUserKey
                })
              }
              if (driverUserKey) {
                this.form.setFieldsValue({
                  'driver.id': driverUserKey
                })
              }
            }
          }
        })
      },
      getSelectKey(list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.LoadUserList()
        this.form.setFieldsValue({
          'carInfo.id': this.formItem.carInfo.id,
          'chargeUser.id': this.formItem.chargeUser.id,
          'driver.id': this.formItem.driver.id,
          ifDefault: this.formItem.ifDefault === true ? 'YES' : 'NO'
        })
      },
      setVOFields(values) {
        var chargeUserKey = values.chargeUser.id.split('_')[2]
        var driverUserKey = values.driver.id.split('_')[2]
        this.formItem.chargeUser.id = chargeUserKey
        this.formItem.driver.id = driverUserKey
        this.formItem.carInfo.id = values.carInfo.id
        this.formItem.ifDefault = values.ifDefault === 'YES'
      }
    }
  }
</script>

<style scoped></style>
