export const carConfigFormVO = function () {
  return {
    chargeUser: {
      id: null,
    },
    driver: {
      id: null,
    },
    carInfo: {
      id: null,
    },
    ifDefault: false
  }
}